import React, { useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet';
import { Button, Image } from 'semantic-ui-react';

import calendarTick from 'assets/images/icons/calendarTick.svg';
import SvgIcon from 'components/SvgIcon';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { calendarReducer, initialCalendarState } from './reducer/reducer';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { CalendarConstants } from './reducer/action';
import CalendarPicker from 'components/CalendarPicker';
import { Reservation } from 'pages/Reservation/types';
import { fetchReservationByDate } from 'services/reservation/fetchReservation';
import ReservationCard from 'components/ReservationCard';
import { useParams } from 'react-router';
import _ from 'lodash';
/** @TODO open calendar selector on icon click */

const Calendar: React.FC = () => {
  const [state, dispatch] = useReducer(calendarReducer, initialCalendarState);

  const { selectedDate } = useParams();

  // On selected date change reload the
  useEffect(() => {
    fetchReservationByDate(state.selectedDate, setCollection, setIsLoading);
  }, [state.selectedDate]);

  // Update state.selectedDate if selectedDate is not undefined
  useEffect(() => {
    if (!_.isUndefined(selectedDate)) {
      dispatch({
        type: CalendarConstants.SET_DATE,
        payload: { selectedDate: new Date(selectedDate) },
      });
    }
  }, [selectedDate]);

  const setIsLoading = (isLoading: boolean) => {
    dispatch({
      type: CalendarConstants.SET_IS_LOADING,
      payload: { isCollectionLoading: isLoading },
    });
  };

  const setCollection = (collection: Array<Reservation>) => {
    dispatch({
      type: CalendarConstants.SET_RESERVATION_COLLECTION,
      payload: { reservationCollection: collection },
    });
  };

  return (
    <>
      <Helmet>
        <title>MSM - Calendario</title>
      </Helmet>
      <div className="calendar-header">
        <div className="calendar-header-left">
          <span
            className="calendar-header-icon"
            onClick={() =>
              dispatch({
                type: CalendarConstants.SET_OPEN_CALENDAR,
                payload: { isCalendarOpen: true },
              })
            }
          >
            <Image src={calendarTick} />
          </span>
          <span className="calendar-header-dates">
            <FormattedMessage
              id="calendar.header.selectedDate"
              defaultMessage="Arrivi per {weekDay}, {completeDate}"
              values={{
                weekDay: moment(state.selectedDate).format('dddd'),
                completeDate: moment(state.selectedDate).format('DD MMMM YYYY'),
              }}
            />
          </span>
          <span className="calendar-header-buttons">
            <Button
              className="only-icon-button"
              content={
                <SvgIcon
                  icon={ICONS.ARROW_LEFT}
                  color={COLORS.WHITE}
                  width={15}
                  height={22}
                />
              }
              onClick={() =>
                dispatch({ type: CalendarConstants.PREVIOUS_DATE })
              }
            />
            <Button
              className="only-icon-button second-button"
              content={
                <SvgIcon
                  icon={ICONS.ARROW_RIGHT}
                  color={COLORS.WHITE}
                  width={15}
                  height={22}
                />
              }
              onClick={() => dispatch({ type: CalendarConstants.NEXT_DATE })}
            />
          </span>
        </div>
      </div>
      <div className="calendar-container">
        <div className="calendar-container-morningslot">
          <div className="calendar-container-morningslot-title">
            <FormattedMessage
              id="calendar.slot.title.morning"
              defaultMessage="Mattina 7:00 - 12:00"
            />
          </div>
          <div className="calendar-container-morningslot-content">
            <div className="calendar-container-morningslot-subcontent">
              {state.morningReservations.map(reservation => (
                <ReservationCard
                  key={reservation.id}
                  reservation={reservation}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="calendar-container-afternoonslot">
          <div className="calendar-container-afternoonslot-title">
            <FormattedMessage
              id="calendar.slot.title.afternoon"
              defaultMessage="Pomeriggio 12:00 - 17:00"
            />
          </div>
          <div className="calendar-container-afternoonslot-content">
            <div className="calendar-container-afternoonslot-subcontent">
              {state.afternoonReservations.map(reservation => (
                <ReservationCard
                  key={reservation.id}
                  reservation={reservation}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <CalendarPicker
        className="calendar-calendar-picker"
        isOpen={state.isCalendarOpen}
        onClose={() =>
          dispatch({
            type: CalendarConstants.SET_OPEN_CALENDAR,
            payload: { isCalendarOpen: false },
          })
        }
        onChange={(date: Date) =>
          dispatch({
            type: CalendarConstants.SET_DATE,
            payload: { selectedDate: date },
          })
        }
        value={state.selectedDate.toString()}
      />
    </>
  );
};

export default Calendar;
