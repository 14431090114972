import React, { useState, useEffect } from 'react';
import { Button, Grid, Image, Segment } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';

// Components
import CertificatesCard from '../SharedComponents/CertificatesCard';

// Icons
import documentIcon from 'assets/images/icons/load-document.svg';

import approvedReservationsIcon from 'assets/images/icons/approvedReservations.svg';
import openOrders from 'assets/images/icons/openOrders.svg';
import originsIcon from 'assets/images/icons/certifications.svg';
import statusDocuments from 'assets/images/icons/statusDocuments.svg';
import bookingCalendar from 'assets/images/icons/bookingCalendar.svg';
import arrow from 'assets/images/icons/arrow.svg';
import calendarAdmin from 'assets/images/icons/calendarAdmin.svg';
import warningCircle from 'assets/images/icons/warning-circle-red.svg';

import { COLORS, FE_ROUTES, ICONS, ROLES } from 'utils/global/globalConstants';
import Card from 'components/Card';
import { isThatRole } from 'utils/function/acl';
import { FormattedMessage, useIntl } from 'react-intl';
import EmptyReservations from 'components/EmptyReservations';
import { SupplierDashboardData } from './types';
import { getSupplierDashboard } from 'services/dashboard/getSupplierDashboard';
import _ from 'lodash';
import SvgIcon from 'components/SvgIcon';
import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

const initialDashboardData: SupplierDashboardData = {
  approvedReservations: 0,
  currentWeekReservations: [],
  expiredDocuments: 0,
  expiringDocuments: 0,
  missingDocuments: 0,
  openOrders: 0,
  originsNumber: 0,
  totalDocuments: 0,
};

const SupplierDashboard: React.FC = () => {
  const navigate = useNavigate();

  const [areDashboardDataLoading, setAreDashboardDataLoading] = useState(false);
  const [dashboardData, setDashboardData] = useState(initialDashboardData);

  const intl = useIntl();

  useEffect(() => {
    getSupplierDashboard(setAreDashboardDataLoading, setDashboardData);
  }, []);

  if (isThatRole(ROLES.SUPPLIER)) {
    return (
      <div className="supplier-dashboard">
        <Grid columns={2}>
          <Grid.Row stretched>
            <Grid.Column width={7}>
              <Grid columns={2}>
                <Grid.Row stretched>
                  <Grid.Column>
                    <Card
                      iconSrc={openOrders}
                      rightIcon={arrow}
                      title={dashboardData.openOrders}
                      subTitle={intl.formatMessage({
                        id: 'supplierDashboard.order',
                        defaultMessage: 'Ordini aperti',
                      })}
                      link={intl.formatMessage({
                        id: 'supplierDashboard.linkToOrder',
                        defaultMessage: 'Vedi ordini',
                      })}
                      linkTo={FE_ROUTES.ORDERS}
                      areDataLoading={areDashboardDataLoading}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Card
                      iconSrc={approvedReservationsIcon}
                      rightIcon={arrow}
                      title={dashboardData.approvedReservations}
                      subTitle={intl.formatMessage({
                        id: 'supplierDashboard.reservationApproved',
                        defaultMessage: 'Prenotazioni approvate',
                      })}
                      link={intl.formatMessage({
                        id: 'supplierDashboard.linkToReservation',
                        defaultMessage: 'Vedi prenotazioni',
                      })}
                      linkTo={`${FE_ROUTES.RESERVATION_LIST}/approved`}
                      areDataLoading={areDashboardDataLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row stretched>
                  <Grid.Column>
                    <Card
                      iconSrc={
                        dashboardData.expiredDocuments > 0 ||
                        dashboardData.missingDocuments > 0 ||
                        dashboardData.expiringDocuments > 0
                          ? warningCircle
                          : statusDocuments
                      }
                      rightIcon={arrow}
                      title={dashboardData.totalDocuments}
                      subTitle={intl.formatMessage({
                        id: 'supplierDashboard.documents',
                        defaultMessage: 'Documentazione',
                      })}
                      link={
                        dashboardData.expiredDocuments > 0 ||
                        dashboardData.missingDocuments > 0 ||
                        dashboardData.expiringDocuments > 0 ? (
                          <FormattedMessage
                            id="supplierDashboard.linkTodocuments.verified"
                            defaultMessage="<toUploadDocuments> documenti da caricare </toUploadDocuments> <expiringDocuments> documenti in scadenza </expiringDocuments> <expiredDocuments> documenti scaduti </expiredDocuments>"
                            values={{
                              expiredDocuments: (text: string) =>
                                dashboardData.expiredDocuments > 0 ? (
                                  <>
                                    {dashboardData.expiredDocuments}
                                    {text}
                                  </>
                                ) : (
                                  ''
                                ),
                              expiringDocuments: (text: string) =>
                                dashboardData.expiringDocuments > 0 ? (
                                  <>
                                    {dashboardData.expiringDocuments}
                                    {text}
                                    <br />
                                    <br />
                                  </>
                                ) : (
                                  ''
                                ),
                              toUploadDocuments: (text: string) =>
                                dashboardData.missingDocuments > 0 ? (
                                  <>
                                    {dashboardData.missingDocuments}
                                    {text}
                                    <br />
                                    <br />
                                  </>
                                ) : (
                                  ''
                                ),
                            }}
                          />
                        ) : (
                          <FormattedMessage
                            id="supplierDashboard.linkTodocuments.show"
                            defaultMessage="Vedi documenti"
                          />
                        )
                      }
                      linkTo={FE_ROUTES.DOCUMENTS}
                      areDataLoading={areDashboardDataLoading}
                    />
                  </Grid.Column>
                  <Grid.Column>
                    <Card
                      iconSrc={originsIcon}
                      rightIcon={arrow}
                      title={dashboardData.originsNumber}
                      subTitle={intl.formatMessage({
                        id: 'supplierDashboard.origins',
                        defaultMessage: 'Provenienze',
                      })}
                      link={intl.formatMessage({
                        id: 'supplierDashboard.linkToOrigins',
                        defaultMessage: 'Vedi provenienze',
                      })}
                      linkTo={`${FE_ROUTES.ORIGINS_CONTACT}`}
                      areDataLoading={areDashboardDataLoading}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
            <Grid.Column width={9}>
              <Segment className="card-grid-header booking-calendar-segment">
                <div className="calendar-header-dashboard">
                  <Image src={bookingCalendar} />
                  <span className="title-text">
                    <FormattedMessage
                      id="supplierDashboard.calendar"
                      defaultMessage="Calendario prenotazioni della settimana"
                    />
                  </span>
                </div>
                {areDashboardDataLoading ? (
                  <div className="booking-calendar-loading">
                    <CircularProgress color="inherit" size={50} />
                    <span className="booking-calendar-loading-text">
                      <FormattedMessage
                        id="supplier.dashboard.booking.calendar.loading"
                        defaultMessage={
                          'Prenotazioni della settimana in caricamento'
                        }
                      />
                    </span>
                  </div>
                ) : (
                  <div className="calendar-content">
                    {!_.isEmpty(dashboardData.currentWeekReservations) ? (
                      dashboardData.currentWeekReservations.map(reservation => (
                        <div
                          key={reservation.id}
                          className="dashboard-reservation-card"
                          onClick={() =>
                            navigate(
                              FE_ROUTES.RESERVATION_DETAILS +
                                '/' +
                                reservation.id,
                            )
                          }
                        >
                          <span className="dashboard-reservation-card-date">
                            <span className="delivery-date">
                              {moment(
                                reservation.deliveryDate,
                                'YYYY-MM-DD',
                              ).format('DD MMM YYYY')}
                            </span>
                            <span className="delivery-timeslot">
                              {reservation.timeSlot === 'morning' ? (
                                <FormattedMessage
                                  id="supplierDashboard.currentWeek.timeslot.morning"
                                  defaultMessage={'MATTINA (07:00 - 12:00)'}
                                />
                              ) : reservation.timeSlot === 'afternoon' ? (
                                <FormattedMessage
                                  id="supplierDashboard.currentWeek.timeslot.afternoon"
                                  defaultMessage={'POMERIGGIO (12:00 - 17:00)'}
                                />
                              ) : null}
                            </span>
                          </span>
                          <span className="dashboard-reservation-card-bookingNumber">
                            {reservation.bookingNumber}
                          </span>
                          <span className="dashboard-reservation-card-vehcilePlate">
                            <SvgIcon
                              icon={ICONS.TOW_TRUCK}
                              color={COLORS.SECONDARY}
                              width={50}
                              height={22}
                            />
                            {reservation.vehiclePlate}
                          </span>
                        </div>
                      ))
                    ) : (
                      <EmptyReservations
                        iconSrc={calendarAdmin}
                        subtitleText={intl.formatMessage({
                          defaultMessage:
                            'Troverai l’elenco di tutte le prenotazioni nella sezione prenotazioni',
                          id: 'supplier.dashboard.empty.reservations.subtitle',
                        })}
                        title={intl.formatMessage({
                          defaultMessage:
                            'Questa settimana non sono previste prenotazioni',
                          id: 'supplier.dashboard.empty.reservations',
                        })}
                      />
                    )}
                  </div>
                )}
                <div
                  className="link-approved-reservation"
                  onClick={() =>
                    navigate(FE_ROUTES.RESERVATION_DETAILS + '/new')
                  }
                >
                  <FormattedMessage
                    id="supplier.dashboard.reservations.link"
                    defaultMessage={'Crea nuova prenotazione'}
                  />
                </div>
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <CertificatesCard />
      </div>
    );
  }

  /**
   * Supplier has not be already approved - show an empty dashbooard with only
   * redirect to documents section for upload
   */
  return (
    <div className="empty-supplier-dashboard">
      <Image src={documentIcon} />
      <div className="text-section">
        <div className="title">
          <FormattedMessage
            id="supplierDashboard.empty.titile"
            defaultMessage="Per poter inserire prenotazioni è necessario caricare i documenti richiesti"
          />
        </div>
        <div className="subtitle">
          <FormattedMessage
            id="supplierDashboard.empty.subtitle"
            defaultMessage="Sarà possibile accedere al servizio non appena avremo certificato tutti i documenti."
          />
        </div>
      </div>
      <Button
        className="main-button"
        content={
          <FormattedMessage
            id="supplierDashboard.linkToDocuments"
            defaultMessage="Vai alla sezione Documenti"
          />
        }
        onClick={() => navigate(FE_ROUTES.DOCUMENTS)}
      />
    </div>
  );
};

export default SupplierDashboard;
