import SvgIcon from 'components/SvgIcon';
import { ErrorMessage, Formik, FormikHelpers } from 'formik';
import { Form, Input, Select } from 'formik-semantic-ui-react';
import { ReservationContext } from 'pages/Reservation';
import { Reservation } from 'pages/Reservation/types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { COLORS, ICONS } from 'utils/global/globalConstants';
import { deliveryInformationSubmit } from './onSubmit';
import * as Yup from 'yup';
import { ReservationConstants } from 'pages/Reservation/reducer/action';
import moment from 'moment';
import _ from 'lodash';
import ReservationCalendarPicker from 'components/ReservationCalendarPicker';
import {
  completeTimeSlot,
  onlyAfternoonTimeSlot,
  onlyMorningTimeSlot,
} from './timeSlotOptions';
import {
  ReservationStateConstants,
  ReservationTimeSlot,
} from 'utils/global/reservationConstants';
import { fetchBookingAvailability } from 'services/reservation/fetchBookingAvailability';

const DeliveryInformationPhase: React.FC<{ readOnly?: boolean }> = ({
  readOnly = false,
}) => {
  const { state, dispatch } = useContext(ReservationContext);
  const { reservationId } = useParams();
  const intl = useIntl();
  const [timeSlotOptions, setTimeSlotOptions] = useState(completeTimeSlot);
  const [timeSlotMessage, setTimeSlotMessage] = useState<string | null>(null);
  const [isCalendarPickerOpen, setIsCalendarPickerOpen] = useState(false);

  const [availableDates, setAvailableDates] = useState<Array<Date>>([]);
  const [availableTimeslot, setAvailableTimeslot] = useState<Array<string>>([]);
  const [areAvailableDateLoaded, setAreAvailableDateLoaded] = useState(false);

  useEffect(() => {
    fetchBookingAvailability(
      setAvailableDates,
      setAvailableTimeslot,
      setAreAvailableDateLoaded,
      state.reservation.bookingNumber,
    );
  }, []);

  const setReservation = (reservation: Reservation) => {
    dispatch({
      type: ReservationConstants.SET_RESERVATION,
      payload: { reservation: reservation },
    });
  };

  const setIsLoading = (isLoading: boolean) => {
    dispatch({
      type: ReservationConstants.SET_RESERVATION_LOADING,
      payload: { isReservationLoading: isLoading },
    });
  };

  const onDeliveryDateChange = (
    date: Date,
    setFieldValue: (
      field: string,
      value: unknown,
      shouldValidate?: boolean,
    ) => void,
  ) => {
    const formattedDate = moment(date).format('YYYY-MM-DD').toString();

    if (
      !_.isEmpty(availableTimeslot[formattedDate]) ||
      moment(date).isSame(new Date(), 'day')
    ) {
      const timeSlot = availableTimeslot[formattedDate];
      if (timeSlot === ReservationTimeSlot.AFTERNOON_SLOT) {
        setTimeSlotOptions(onlyMorningTimeSlot);
        setTimeSlotMessage(
          intl.formatMessage({
            id: 'deliveryInformation.timeslot.onlyMorning',
            defaultMessage:
              'Per il giorno selezionato è disponibile solamente la fascia oraria mattina 7:00 - 12:00',
          }),
        );
      } else if (timeSlot === ReservationTimeSlot.MORNING_SLOT) {
        setTimeSlotOptions(onlyAfternoonTimeSlot);
        setTimeSlotMessage(
          intl.formatMessage({
            id: 'deliveryInformation.timeslot.onlyAfternoon',
            defaultMessage:
              'Per il giorno selezionato è disponibile solamente la fascia oraria pomeriggio 12:00 - 17:00',
          }),
        );
      } else if (moment(date).isSame(new Date(), 'day')) {
        setTimeSlotOptions(onlyAfternoonTimeSlot);
        setTimeSlotMessage(
          intl.formatMessage({
            id: 'deliveryInformation.timeslot.onlyAfternoonToday',
            defaultMessage:
              'Per il giorno stesso è disponibile solamente la fascia oraria pomeriggio 12:00 - 17:00',
          }),
        );
      }
    } else {
      setTimeSlotOptions(completeTimeSlot);
      setTimeSlotMessage(null);
    }

    // Clear the selected timeslot
    setFieldValue('timeSlot', null);
    setFieldValue('deliveryDate', formattedDate);
  };

  const initialValues = useMemo<Reservation>(() => {
    if (state.reservation) {
      return state.reservation;
    }

    return {
      id: '',
      state: ReservationStateConstants.NEW,
      originCode: null,
      deliveryDate: null,
      timeSlot: null,
      vehiclePlate: '',
      reservationLines: [],
      bookingNumber: '',
      shippingAgent: '',
      documentsCount: 0,
    };
  }, [state.reservation]);

  return (
    <div className="delivery-information">
      <div className="reservation-header">
        <span className="reservation-header-title">
          <FormattedMessage
            id="deliveryInformation.title"
            defaultMessage="Data consegna"
          />
        </span>
        <span className="deliveryInformation-header-subtitle">
          <FormattedMessage
            id="deliveryInformation.subtitle"
            defaultMessage="È possibile selezionare una data di consegna dal giorno successivo"
          />
        </span>
      </div>

      <div className="deilvery-information-content">
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(
            values: Reservation,
            formikHelpers: FormikHelpers<Reservation>,
          ) => {
            deliveryInformationSubmit(
              values,
              reservationId,
              setIsLoading,
              setReservation,
              formikHelpers,
            );
          }}
          validationSchema={Yup.object().shape({
            timeSlot: Yup.string()
              .nullable()
              .required(
                intl.formatMessage({
                  id: 'deliveryInformation.error.timeslot.required',
                  defaultMessage: 'Selezionare una fascia oraria',
                }),
              ),
            deliveryDate: Yup.string()
              .nullable()
              .required(
                intl.formatMessage({
                  id: 'deliveryInformation.error.deliveryDate.required',
                  defaultMessage: 'Selezionare un giorno per la spedizione',
                }),
              ),
          })}
        >
          {({ values, setFieldValue }) => (
            <Form id={state.reservation?.state}>
              <div className="reservation-fields-row">
                <div className="fields-with-icon">
                  <SvgIcon
                    icon={ICONS.CALENDAR_FLAT}
                    color={COLORS.SECONDARY}
                    height={32}
                    width={32}
                  />
                  <div className="fields-content">
                    <Input
                      name="deliveryDate"
                      placeholder={intl.formatMessage({
                        id: 'deliveryInformation.placeholder.deliveryDate',
                        defaultMessage: 'Selezionare una data',
                      })}
                      label={intl.formatMessage({
                        id: 'deliveryInformation.label.deliveryDate',
                        defaultMessage: 'Data di consegna *',
                      })}
                      value={
                        values.deliveryDate
                          ? moment(values.deliveryDate).format('DD/MM/YYYY')
                          : ''
                      }
                      readOnly
                      loading={!areAvailableDateLoaded}
                      onClick={() => {
                        if (areAvailableDateLoaded && !readOnly) {
                          setIsCalendarPickerOpen(true);
                        }
                      }}
                    />
                    <span className="delivery-fields-description">
                      <FormattedMessage
                        id="deliveryInformation.description.deliveryDate"
                        defaultMessage="Indicare il giorno di consegna del materiale."
                      />
                    </span>
                    <ErrorMessage
                      name="deliveryDate"
                      render={message => (
                        <span className="default-error-message">{message}</span>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="reservation-fields-row">
                <div className="fields-with-icon">
                  <SvgIcon
                    icon={ICONS.CLOCK}
                    color={COLORS.SECONDARY}
                    height={32}
                    width={32}
                  />
                  <div className="fields-content">
                    <Select
                      name="timeSlot"
                      placeholder={intl.formatMessage({
                        id: 'deliveryInformation.placeholder.timeSlot',
                        defaultMessage: 'Selezionare una fascia oraria',
                      })}
                      label={intl.formatMessage({
                        id: 'deliveryInformation.label.timeSlot',
                        defaultMessage: 'Fascia Oraria *',
                      })}
                      options={timeSlotOptions}
                      disabled={readOnly}
                    />
                    <span className="delivery-fields-description">
                      <FormattedMessage
                        id="deliveryInformation.description.timeSlot"
                        defaultMessage="Indicare la fascia oraria."
                      />
                    </span>
                    <ErrorMessage
                      name="timeSlot"
                      render={message => (
                        <span className="default-error-message">{message}</span>
                      )}
                    />
                  </div>
                </div>
                {!_.isEmpty(timeSlotMessage) && (
                  <div className="reservation-timeslot-description">
                    {timeSlotMessage}
                  </div>
                )}
              </div>
              <div className="reservation-fields-row reservation-border-top">
                <div className="fields-with-icon">
                  <SvgIcon
                    icon={ICONS.TRUCK_FRONTAL}
                    color={COLORS.SECONDARY}
                    height={32}
                    width={32}
                  />
                  <div className="fields-content">
                    <Input
                      name="vehiclePlate"
                      placeholder={intl.formatMessage({
                        id: 'deliveryInformation.placeholder.vehiclePlate',
                        defaultMessage: 'Inserire targa veicolo',
                      })}
                      label={intl.formatMessage({
                        id: 'deliveryInformation.label.vehiclePlate',
                        defaultMessage: 'Targa veicolo',
                      })}
                      disabled={readOnly}
                      className="input-uppercase"
                    />
                    <span className="delivery-fields-description">
                      <FormattedMessage
                        id="deliveryInformation.description.vehiclePlate"
                        defaultMessage="Indicare la targa del veicolo che effettuerà la consegna."
                      />
                    </span>
                    <ErrorMessage
                      name="vehiclePlate"
                      render={message => (
                        <span className="default-error-message">{message}</span>
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="reservation-fields-row">
                <div className="fields-with-icon">
                  <SvgIcon
                    icon={ICONS.USER}
                    color={COLORS.SECONDARY}
                    height={32}
                    width={32}
                  />
                  <div className="fields-content">
                    <Input
                      name="shippingAgent"
                      placeholder={intl.formatMessage({
                        id: 'deliveryInformation.placeholder.shippingAgent',
                        defaultMessage: 'Inserire nome spedizioniere',
                      })}
                      label={intl.formatMessage({
                        id: 'deliveryInformation.label.shippingAgent',
                        defaultMessage: 'Spedizioniere',
                      })}
                      disabled={readOnly}
                      //className="input-uppercase"
                    />
                    <span className="delivery-fields-description">
                      <FormattedMessage
                        id="deliveryInformation.description.shippingagent"
                        defaultMessage="Indicare il nomde dello spedizioniere che effettuerà la consegna."
                      />
                    </span>
                  </div>
                </div>
              </div>

              <ReservationCalendarPicker
                availableDays={availableDates}
                minDate={moment()}
                maxDate={moment().add(3, 'M')}
                className="delivery-information-calendar-picker"
                isOpen={isCalendarPickerOpen}
                onClose={() => setIsCalendarPickerOpen(false)}
                onChange={(date: Date) =>
                  onDeliveryDateChange(date, setFieldValue)
                }
                value={values.deliveryDate}
              />
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default DeliveryInformationPhase;
